/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2023 FotoFinder Systems GmbH
 ***
 ***  Last update: 07.08.23.sc
 ***
 *****************************************************************/


import Modal from "../base_modal";

export default class extends Modal {

  static targets = ["legacyPlansBox", "dermoscopyPlansBox", "aestheticsPlansBox", "dermoscopy_aestheticsPlansBox", "premiumBox", "nonPremiumBox", "addLoginsBox"];

  open(event) {
    const containerNames = event.currentTarget.dataset.containerOpen;
    if (containerNames !== undefined) {
      event.preventDefault();
      this.findContainers(containerNames).forEach((container) => {
        container.classList.remove(this.toggleClass);
      })
      this.restoreScrollPosition();
    } else {
      if (event.currentTarget.dataset.modalId !== undefined) {
        event.preventDefault();
        document.getElementById(event.currentTarget.dataset.modalId).classList.remove("hidden");
        this.restoreScrollPosition();
      } else {
        super.open(event);
      }
    }
  }

  close(event) {
    if (!event || !event.currentTarget) return;
    const containerNames = event.currentTarget.dataset.containerClose;
    if (containerNames !== undefined) {
      event.preventDefault();
      this.findContainers(containerNames).forEach((container) => {
        container.classList.add(this.toggleClass);
      })
      this.restoreScrollPosition();
    } else {
      if (event.currentTarget.dataset.modalId !== undefined) {
        event.preventDefault();
        document.getElementById(event.currentTarget.dataset.modalId).classList.add("hidden");
        this.restoreScrollPosition();
      } else {
        super.close(event);
      }
    }
  }

  findContainers(names) {
    return this.containerTargets.filter(container => names.split(/\s+/).includes(container.dataset.containerName));
  }

  closeBackground(event) {
    if (event.target.dataset.background !== undefined) {
      event.preventDefault();
      this[`${event.currentTarget.dataset.background}Target`].classList.add("hidden");
      this.restoreScrollPosition();
    } else {
      super.closeBackground(event);
    }
  }

  setupModal(event) {
    var form = document.getElementById("assessment-modal-form");
    if (event.currentTarget.dataset.mode == "editAssessment") {
      this.transformToEditForm(form);
    } else if (event.currentTarget.dataset.mode == "addAssessment") {
      this.transformToCreateForm(form);
    }
    this.toggleModalTitle();
  }

  toggleModalTitle() {
    document.getElementById("addTitle").classList.toggle("hidden");
    document.getElementById("editTitle").classList.toggle("hidden");
  }

  transformToEditForm(form) {
    // set selected assessment name to input field
    document.getElementById("assessment-modal-name").value = event.currentTarget.dataset.assessmentName;
    // provide hidden field to mark update action
    var hiddenPUTField = document.createElement("input");
    hiddenPUTField.setAttribute("type", "hidden");
    hiddenPUTField.setAttribute("name", "_method");
    hiddenPUTField.setAttribute("value", "put");
    hiddenPUTField.setAttribute("autocomplete", "off");
    form.appendChild(hiddenPUTField);
    // change form URL
    form.action = "/settings/assessments/"+event.currentTarget.dataset.id;
  }

  transformToCreateForm(form) {
    // reset form value
    document.getElementById("assessment-modal-name").value = "";
    // change form URL
    form.action = "/settings/assessments/";
    // remove update action hidden field
    const methodInput = form.querySelector(`input[name="_method"`);
    if(methodInput) methodInput.remove();
  }

  restoreScrollPosition() {
    const currentScrollPosition = window.scrollY;
    window.scrollTo(0, currentScrollPosition);
  }

  passFormValue(event) {
    const originalForm = document.getElementById(event.currentTarget.dataset.mainForm);
    const form = document.getElementById(event.currentTarget.dataset.formId);
    if (originalForm && form) form.querySelector("#feature_level").value = originalForm.querySelector("#feature_level").value;
  }

  doLogin(event) {
    this.rememberMeCheckbox = this.element.querySelector('input[name="user[remember_me]"]');
    if (this.rememberMeCheckbox.checked) {
      event.preventDefault();
      this.open(event);
    }
  }

  uncheck(event) {
    event.preventDefault();
    this.rememberMeCheckbox = this.element.querySelector('input[name="user[remember_me]"]');
    this.rememberMeCheckbox.checked = false
  }
}
