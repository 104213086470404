import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = [
        "deviceCount",
        "storageSize",
        "decreaseDevice",
        "decreaseSize",
        "totalPriceDevice",
        "totalPriceStorage",
    ];

    connect() {
        // Disable the decrease button when the controller is first connected
        this.decreaseDeviceTarget.disabled = true;
        this.decreaseSizeTarget.disabled = true;
    }

    // General method to increase or decrease the count
    updateValue(type, change) {
        event.preventDefault();

        if (type === "device") {
            let currentCount = parseInt(this.deviceCountTarget.textContent) || 0;
            let newCount = currentCount + change;

            // Update device count display
            this.deviceCountTarget.textContent = newCount > 1 ? `${newCount} Devices` : "1 Device";

            // Enable/Disable decrease button based on device count
            this.decreaseDeviceTarget.classList.toggle("text-graylight", newCount <= 1);
            this.decreaseDeviceTarget.classList.toggle("bg-ebony-clay-alt", newCount > 1);
        } else if (type === "storage") {
            let currentSize = parseInt(this.storageSizeTarget.textContent) || 0;
            let newSize = currentSize + change;

            // Update storage size display
            this.storageSizeTarget.textContent = newSize > 512 ? `${newSize} GB` : "512 GB";

            // Enable/Disable decrease button based on storage size
            this.decreaseSizeTarget.classList.toggle("text-graylight", newSize <= 512);
            this.decreaseSizeTarget.classList.toggle("bg-ebony-clay-alt", newSize > 512);
        }
    }

    increaseDevice() {
        this.updateValue("device", 1);
        // Update total price after each change
        this.updateTotalPrice("device");
    }

    decreaseDevice() {
        this.updateValue("device", -1);
        // Update total price after each change
        this.updateTotalPrice("device");
    }

    increaseStorage() {
        this.updateValue("storage", 512);
        this.updateTotalPrice("storage");
    }

    decreaseStorage() {
        this.updateValue("storage", -512);
        this.updateTotalPrice("storage");
    }

    updateTotalPrice(type) {
        let totalPriceDevice = parseInt(this.totalPriceDeviceTarget?.textContent.slice(1)) || 0;
        let totalPriceStorage = parseInt(this.totalPriceStorageTarget?.textContent.slice(1)) || 0;

        const deviceCount = parseInt(this.deviceCountTarget.textContent) || 0;
        const storageSize = parseInt(this.storageSizeTarget.textContent) || 0;

        // Disable the decrease button
        this.decreaseDeviceTarget.disabled = (deviceCount == 1);
        this.decreaseSizeTarget.disabled = (storageSize == 512);

        const unitCost = 25; // Cost per additional

        // Calculate total based on device and storage counts
        switch (type) {
            case "device":
                totalPriceDevice = unitCost * deviceCount;
                break;
            case "storage":
                totalPriceStorage = unitCost * (storageSize / 512);
                break;
        }

        // Update the total price display if targets are available
        if (this.totalPriceDeviceTarget) {
            this.totalPriceDeviceTarget.textContent = `€${totalPriceDevice}`;
        }
        if (this.totalPriceStorageTarget) {
            this.totalPriceStorageTarget.textContent = `€${totalPriceStorage}`;
        }
    }
}
