import Modal from "../base_modal";

export default class extends Modal {

  connect() {
    super.connect();
  }

  open(event) {
    event.preventDefault();
    var userID = event.currentTarget.dataset.userId;
    var modalTarget = event.currentTarget.dataset.modalTarget;
    this.mainContainer = document.querySelector(`#${modalTarget}${userID}`);
    this.mainContainer.classList.remove("hidden");
    document.body.insertAdjacentHTML('beforeend', this._backgroundHTML());
    this.background = document.querySelector(`#${modalTarget}${userID}-background`);
  }

  close(event) {
    if(event) { 
      event.preventDefault();
    // unlock the scroll
      this.unlockScroll();
      document.querySelector(`#${event.currentTarget.dataset.modalTarget}${event.currentTarget.dataset.userId}`).classList.add("hidden");

      // remove background
      if (this.background) { this.background.remove() }
    } else {
      super.close(event);
    }
  }

  closeBackground(e) {
    if (this.allowBackgroundClose && e.target === this.mainContainer) {
      this.close(e);
    }
  }
}