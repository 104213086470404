import { Controller } from "@hotwired/stimulus";

// Constants for better maintainability and performance
const PRICE_CONFIG = {
    BASE_PRICE: 99,
    ADDITIONAL_MODULE: 50,
    ADDITIONAL_UNIT: 25,
    MIN_DEVICE_COUNT: 1,
    MIN_STORAGE_SIZE: 512
};

const CSS_CLASSES = {
    ACTIVE: {
        CONTAINER: 'bg-ffred',
        CHECKBOX: ['border-white', 'text-ffredl']
    },
    INACTIVE: {
        CONTAINER: 'bg-ebony-clay-alt',
        MESSAGE: 'text-lightbluegray'
    },
    BUTTON: {
        DISABLED: 'text-graylight',
        ENABLED: 'bg-ebony-clay-alt'
    }
};

const PREFIX_PATH = '/assets/svg/settings/';

/**
 * Grid Subscription Controller
 * Manages subscription pricing, device counts, and storage options
 */
export default class extends Controller {
    static targets = [
        "gridShow", "deviceCount", "storageSize", "decreaseDevice", "icon",
        "decreaseSize", "totalPrice", "totalPriceTooltip", "button", "exclusiveDerPrice", "grid"
    ];

    connect() {
        this._initializeState();
    }

    change(event) {
        const { target: checkbox } = event;
        const container = this._getContainer(checkbox);
        const message = this._getMessage(container);
        const checkedModules = this._getCheckedModules();

        // Prevent unchecking the last module
        if (checkedModules.length === 0 && !checkbox.checked) {
            checkbox.checked = true;
            return;
        }

        this._updateModuleSelection(checkbox, checkedModules);
        this._disable_module(checkedModules);
        this._updateModuleStyles(checkbox, container, message);
        this.updateTotalPrice(checkbox.checked ? "choseCheckBox" : "unCheckBox");
    }

    toggleProposer() {
        const elements = {
            lastRow: document.getElementById("lastRow"),
            lastRowFirst: document.getElementById("lastRowFirst"),
            lastRowFirstTablet: document.getElementById("lastRowFirstTablet"),
            lastRowTablet: document.getElementById("lastRowTablet"),
            seeMore: document.getElementById("seeMore"),
            seeLess: document.getElementById("seeLess")
        };

        this._toggleGridVisibility(elements);
    }

    updateValue(type, change) {

        if (type === "device") {
            const addDeviceFields =  document.querySelectorAll('[class*="f_device"]');
            let currentCount = parseInt(this.deviceCountTarget.textContent) || 0;
            let newCount = currentCount + change;

            // Update device count display
            this.deviceCountTargets.forEach(target => {
                target.textContent = newCount > 1 ? `${newCount} Devices` : "1 Device";
            });

            addDeviceFields.forEach(field => {
                let currentValue = parseInt(field.value) || 0;
                field.value = currentValue + (change < 0 ? -1 : 1);
            });
            const isCountValid = newCount > 1;

            // Enable/Disable decrease button based on device count
            this.decreaseDeviceTargets.forEach(target => {
                target.classList.toggle("text-graylight", !isCountValid);
                target.classList.toggle("bg-ebony-clay-alt", isCountValid);
            });

            if (isCountValid) {
                this._updateTooltip("device_tooltips", `
                    <div>Devices</div>
                    <div class="px-2">€${(newCount-1)*PRICE_CONFIG.ADDITIONAL_UNIT}/month</div>
                `);
            } else {
                this._hideTooltip("device_tooltips");

            }
        } else if (type === "storage") {
            const addStorageFields = document.querySelectorAll('[class*="f_storage"]');
            let currentSize = parseInt(this.storageSizeTarget.textContent) || 0;
            let newSize = currentSize + change;

            this.storageSizeTargets.forEach(target => {
                target.textContent = newSize > 512 ? `${newSize} GB` : "512 GB";
            });

            addStorageFields.forEach(field => {
                let currentValue = parseInt(field.value) || 0;
                field.value = currentValue + (change < 0 ? -1 : 1);
            });
            // Enable/Disable decrease button based on storage size
            const isStorageSizeValid = newSize > 512;
            this.decreaseSizeTargets.forEach(target => {
                target.classList.toggle("text-graylight", !isStorageSizeValid);
                target.classList.toggle("bg-ebony-clay-alt", isStorageSizeValid);
            });

            if (isStorageSizeValid) {
                this._updateTooltip("storage_tooltips", `
                    <div>Storage</div>
                    <div class="px-2">€${(newSize/512 -1)*PRICE_CONFIG.ADDITIONAL_UNIT}/month</div>
                `);
            } else {
                this._hideTooltip("storage_tooltips");
            }
        }

        this.updateTotalPrice(change > 0 ? "increase" : "decrease");
    }

    // Convenience methods for value updates
    increaseDevice(event) {
        event.preventDefault();
        this.updateValue("device", PRICE_CONFIG.MIN_DEVICE_COUNT);
    }

    decreaseDevice(event) {
        event.preventDefault();
        this.updateValue("device", -PRICE_CONFIG.MIN_DEVICE_COUNT);
    }

    increaseStorage(event) {
        event.preventDefault();
        this.updateValue("storage", PRICE_CONFIG.MIN_STORAGE_SIZE);
    }

    decreaseStorage(event) {
        event.preventDefault();
        this.updateValue("storage", -PRICE_CONFIG.MIN_STORAGE_SIZE);
    }

    updateTotalPrice(type) {
        let totalPrice = parseInt(this.totalPriceTarget?.textContent.slice(1)) || 0;

        const deviceCount = parseInt(this.deviceCountTarget.textContent) || 0;
        const storageSize = parseInt(this.storageSizeTarget.textContent) || 0;

        // Disable the decrease buttons based on minimum values
        this.decreaseDeviceTargets.forEach(target => {
            target.disabled = (deviceCount == PRICE_CONFIG.MIN_DEVICE_COUNT);
        });

        this.decreaseSizeTargets.forEach(target => {
            target.disabled = (storageSize == PRICE_CONFIG.MIN_STORAGE_SIZE);
        });

        // Calculate total based on action type
        switch (type) {
            case "increase":
                totalPrice += PRICE_CONFIG.ADDITIONAL_UNIT;
                break;
            case "decrease":
                totalPrice -= PRICE_CONFIG.ADDITIONAL_UNIT;
                break;
            case "choseCheckBox":
                totalPrice += PRICE_CONFIG.ADDITIONAL_MODULE;
                break;
            case "unCheckBox":
                totalPrice -= PRICE_CONFIG.ADDITIONAL_MODULE;
                break;
        }

        // Update price displays
        this.totalPriceTargets.forEach(target => {
            target.textContent = `€${totalPrice}`;
        });
        this.totalPriceTooltipTargets.forEach(target => {
            target.textContent = `€${totalPrice}/month`;
        });

        return totalPrice;
    }

    // Private helper methods
    _initializeState() {
        this.decreaseDeviceTargets.forEach(target => target.disabled = true);
        this.decreaseSizeTargets.forEach(target => target.disabled = true);
        const aestheticsSelections = document.querySelectorAll('[class*="f_aesthetics"]');
        aestheticsSelections.forEach(selection => {
            selection.disabled = true;
        });
    }

    _getContainer(checkbox) {
        return checkbox.closest('[data-grid-subscription-target="container"]');
    }

    _getMessage(container) {
        return container.querySelector('[data-grid-subscription-target="message"]');
    }

    _getCheckedModules() {
        return Array.from(this.element.querySelectorAll('input[type="checkbox"]'))
            .filter(cb => cb.checked)
            .map(cb => cb.id);
    }

    _updateModuleSelection(checkbox, checkedModules) {
        const moduleFields = document.querySelectorAll(`[class*="f_${checkbox.id}"]`);
        const derAesElement = document.getElementById("der_aes");
        moduleFields.forEach(field => {
            field.disabled = !checkbox.checked;
        });

        if (checkedModules.length > 1) {
            this._updateTooltip("additional_module_tooltips",
                this._createModuleTooltip(checkedModules.length));
            if (derAesElement) {
                derAesElement.classList.remove("hidden");
            }
        } else {
            this._hideTooltip("additional_module_tooltips");
            if (derAesElement) {
                derAesElement.classList.add("hidden");
            };
        }

        if (checkedModules.length > 0) {
            this._updateTooltip("package_tooltips",
                this._createPackageTooltip(checkedModules[0]));
        }
    }

    _updateModuleStyles(checkbox, container, message) {
        const { checked } = checkbox;
        container.classList.toggle(CSS_CLASSES.ACTIVE.CONTAINER, checked);
        container.classList.toggle(CSS_CLASSES.INACTIVE.CONTAINER, !checked);

        CSS_CLASSES.ACTIVE.CHECKBOX.forEach(cls =>
            checkbox.classList.toggle(cls, checked));

        message.classList.toggle(CSS_CLASSES.INACTIVE.MESSAGE, !checked);
    }

    _toggleGridVisibility(elements) {
        this.gridShowTargets.forEach(target => target.classList.toggle("hidden"));
        const isVisible = !this.gridShowTargets[0].classList.contains("hidden");
        this._updateGridStyles(elements, isVisible);
    }

    _updateGridStyles(elements, isVisible) {
        const { lastRow, lastRowFirst, lastRowFirstTablet, lastRowTablet,
            seeMore, seeLess } = elements;

        if (!isVisible) {
            if (lastRow) {
                lastRow.classList.add("rounded-br-lg", "!border-b-bgred");
            }
            if (lastRowFirst) {
                lastRowFirst.classList.add("rounded-bl-lg");
            }
            if (seeMore) seeMore.classList.remove("hidden");
            if (seeLess) seeLess.classList.add("hidden");
            if (lastRowFirstTablet) {
                lastRowFirstTablet.classList.add("sm:rounded-bl-lg");
            }
            if (lastRowTablet) {
                lastRowTablet.classList.add("sm:!border-b", "sm:!border-b-bgred", "sm:rounded-br-lg");
            }
        } else {
            if (lastRow) {
                lastRow.classList.remove("rounded-br-lg", "!border-b-bgred");
            }
            if (lastRowFirst) {
                lastRowFirst.classList.remove("rounded-bl-lg");
            }
            if (seeMore) seeMore.classList.add("hidden");
            if (seeLess) seeLess.classList.remove("hidden");
            if (lastRowFirstTablet) {
                lastRowFirstTablet.classList.remove("sm:rounded-bl-lg");
            }
            if (lastRowTablet) {
                lastRowTablet.classList.remove("sm:!border-b", "sm:!border-b-bgred", "sm:rounded-br-lg");
            }
        }
    }

    _updateTooltip(id, content) {
        const element = document.getElementById(id);
        if (element) {
            element.innerHTML = content;
            element.classList.remove("hidden");
        }
    }

    _hideTooltip(id) {
        const element = document.getElementById(id);
        if (element) {
            element.classList.add("hidden");
        }
    }

    _createModuleTooltip(moduleCount) {
        return `
            <div>Additional Module</div>
            <div class="px-2">€${(moduleCount-1) * PRICE_CONFIG.ADDITIONAL_MODULE}/month</div>
        `;
    }

    _createPackageTooltip(packageName) {
        return `
            <div class="capitalize">${packageName}</div>
            <div class="px-2">€${PRICE_CONFIG.BASE_PRICE}/month</div>
        `;
    }

    _disable_module(checkedModules){
        if( checkedModules.length < 2 && checkedModules[0] === "dermoscopy" ){
            this._activate();
        }
        else{
            this._deactivate();
        }
    }

    _activate() {
        this.iconTargets.forEach(icon => {
            const basePath = icon.dataset.basePath
            icon.src = `${PREFIX_PATH}${basePath}.svg`
        })
        this.gridTarget.classList.remove("text-grid-dim")
        this.gridShowTargets.forEach(grid => grid.classList.remove("text-grid-dim"))
        this.buttonTargets.forEach(button => {
            button.classList.remove("bg-ebony-clay-alt", "pointer-events-none")
            button.classList.add("bg-ffred")
        })
        this.exclusiveDerPriceTargets.forEach(price => {
            price.textContent = price.dataset.originalPrice
        })
    }

    _deactivate() {
        this.iconTargets.forEach(icon => {
            const basePath = icon.dataset.basePath
            icon.src = `${PREFIX_PATH}${basePath}_gray.svg`
        })
        this.gridTarget.classList.add("text-grid-dim")
        this.gridShowTargets.forEach(grid => grid.classList.add("text-grid-dim"))
        this.buttonTargets.forEach(button => {
            button.classList.add("bg-ebony-clay-alt", "pointer-events-none")
            button.classList.remove("bg-ffred")
        })
        this.exclusiveDerPriceTargets.forEach(price => {
            price.textContent = "-"
        })
    }

    toggleMobile(event) {
        const container = event.currentTarget.closest('[data-product]');
        const grid = container.querySelector('.grid-cols-2');
        const seeMore = container.querySelector('[data-see-more]');

        grid.classList.toggle('hidden');
        seeMore.classList.toggle('hidden');
    }
}