import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    connect() {
        this.scrollToActiveTab();
    }

    scrollToActiveTab() {
        const activeTab = this.element.querySelector('.active-cm-tab')
        if (activeTab) {
            setTimeout(() => {
                activeTab.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'center'
                })
            }, 100)
        }
    }
}
