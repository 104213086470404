import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    console.log('Sidebar CONTROLLER connected!');
  }

  toggleSidebar(event) {
    event.preventDefault();
    let openSidebarBtn = document.querySelector("#show-side-navbar-btn");
    let element = this.element;
    let settingsCloseIcon = element.querySelector("#settings-close");
    let settingsOpenIcon = element.querySelector("#settings-open");
    let settingsDropdown = element.querySelector("#settings-dropdown");
    if(settingsDropdown) {
      this.toggleElement("#settings-dropdown");
      settingsCloseIcon.classList.toggle("hidden");
      settingsOpenIcon.classList.toggle("hidden");
    }
    if($(openSidebarBtn).is(":visible")) {
      openSidebarBtn.dispatchEvent(new Event("click"));
    }
  }

  toggleElement(selector) {
    if($(selector).css('display') === 'none') {
      $(selector).css('display', 'block');
    } else {
      $(selector).css('display', 'none');
    }
  }

  showAssessments(event) {
    if (event.currentTarget.dataset.value === 'assessments') {
      event.preventDefault();
      event.currentTarget.classList.toggle("accordion-active");
      var asmtElements = document.getElementById("assessment-elements");
      asmtElements.classList.toggle("hidden");
    }
  }
}
