import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";
export default class extends Controller {
    static targets = ["storageSpaceChart", "usersConnectChart", "browserChart", "deviceChart"]

    // Save references to chart instances
    charts = {
        storageSpaceChart: null,
        usersConnectChart: null,
        browserChart: null,
        deviceChart: null
    }

    // Cleanup controller disconnect
    disconnect() {
        // Destroy charts instances
        Object.values(this.charts).forEach(chart => {
            if (chart) {
                chart.destroy();
            }
        });
        // Reset charts object
        this.charts = {
            storageSpaceChart: null,
            usersConnectChart: null,
            browserChart: null,
            deviceChart: null
        };
    }

    connect() {
        let percentage = parseFloat(this.storageSpaceChartTarget.dataset.percentageStorageSpace);
        let usedDevices = parseFloat(this.deviceChartTarget.dataset.usedDevices);
        let maxDevices = parseFloat(this.deviceChartTarget.dataset.maxDevices);
        let usedBrowsers = parseFloat(this.browserChartTarget.dataset.usedBrowsers);
        let maxBrowsers = parseFloat(this.browserChartTarget.dataset.maxBrowsers);

        this.drawChart("storageSpaceChart", percentage, 'percentage');
        this.drawChart("usersConnectChart", null, 'ratio', usedDevices, maxDevices);
        this.drawChart("browserChart", null, 'ratio', usedBrowsers, maxBrowsers);
        this.drawChart("deviceChart", null, 'ratio', usedDevices, maxDevices);

        // Add resize listener
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    handleResize() {
        const width = window.innerWidth;
        // Redraw all charts when screen size changes
        Object.keys(this.charts).forEach(chartKey => {
            if (this.charts[chartKey]) {
                const chart = this.charts[chartKey];
                const currentOptions = chart.opts;

                // Update font size based on screen width
                const fontSize = (width >= 768 && width <= 1024) ? '20px' : '24px';

                currentOptions.plotOptions.radialBar.dataLabels.value.fontSize = fontSize;

                // Update the chart with new options
                chart.updateOptions(currentOptions, false, true);
            }
        });
    }

    drawChart(target, value, type, used = 0, total = 0) {
        // Destroy existing chart if it exists
        if (this.charts[target]) {
            this.charts[target].destroy();
            this.charts[target] = null;
        }

        let percentage;
        let color = '';
        let valueChart = '';
        let emptySpaceColor = '#4D555D';

        if (type === 'percentage') {
            value > 0 ? percentage = Math.max(1, Math.min(100, value)) : percentage = 0;
            percentage = Number(percentage.toFixed(2));
            valueChart = `${percentage}%`
        } else if (type === 'ratio') {
            percentage = (used / total) * 100;
            percentage = percentage > 100 ? 100 : percentage;
            valueChart = `${used}/${total}`
        }

        let chartLabel = 'Used';
        // Set the label based on the target
        switch (target) {
            case 'browserChart':
                chartLabel = 'Browser';
                break;
            case 'deviceChart':
                chartLabel = 'Device';
                break;
        }

        if (percentage > 0 && percentage <= 75) {
            color = '#54B854';
            emptySpaceColor = '#3D3D3D';
        } else if (percentage >= 76 && percentage <= 90) {
            color = '#F1AB24';
        } else if (percentage >= 91) {
            color = '#C12B23';
        }

        // Get initial font size based on current screen width
        const width = window.innerWidth;
        const fontSizeName = (width >= 768 && width <= 1024) ? '20px' : '24px';

        const options = {
            chart: {
                type: 'radialBar',
            },
            height: 129,
            series: [percentage],
            labels: [chartLabel],
            colors: [color],
            plotOptions: {
                radialBar: {
                    track: {
                        background: emptySpaceColor, // Set the color of the empty space
                        strokeWidth: '100%',
                    },
                    hollow: {
                        size: '62%',
                    },
                    dataLabels: {
                        name: {
                            fontSize: '14px',
                            offsetY: 25,
                            fontFamily: "Source Sans Pro",
                            color: '#fff',
                            fontWeight: 400,
                            lineHeight: '160%',
                            letterSpacing: '0.014px'
                        },
                        value: {
                            fontSize: fontSizeName, // Use dynamic fontSize
                            color: '#fff',
                            offsetY: -20,
                            fontFamily: "Source Sans Pro",
                            fontWeight: 700,
                            lineHeight: '160%', // Set line-height
                            letterSpacing: '0.024px',
                            formatter: function () {
                                return valueChart;
                            }
                        }
                    }
                }
            },
            states: {
                normal: {
                    filter: {
                        type: 'none', // Disable any effect in normal state
                    }
                },
                hover: {
                    filter: {
                        type: 'none', // Disable any effect on hover
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none', // Disable any effect on click
                    }
                }
            }
        };

        let chartTarget;
        switch (target) {
            case 'storageSpaceChart':
                chartTarget = this.storageSpaceChartTarget;
                break;
            case 'usersConnectChart':
                chartTarget = this.usersConnectChartTarget;
                break;
            case 'browserChart':
                chartTarget = this.browserChartTarget;
                break;
            case 'deviceChart':
                chartTarget = this.deviceChartTarget;
                break;
            default:
                console.error('Invalid chart target');
                return;
        }

        // Create new chart and store reference
        this.charts[target] = new ApexCharts(chartTarget, options);
        this.charts[target].render();
    }
}